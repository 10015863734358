import { graphql, useStaticQuery } from "gatsby"

const usePrismicFooterQuery = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allPrismicFooter {
        edges {
          node {
            data {
              company_address {
                html
              }
              registration_info {
                html
              }
              phone_number_1 {
                text
              }
              phone_number_2 {
                text
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicFooter.edges[0].node.data
}

export default usePrismicFooterQuery
