import "./styles.scss"

import { graphql, useStaticQuery } from "gatsby"

import Footer from "../Footer/Footer"
import Header from "../Header"
import React from "react"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="layout">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <input type="hidden" name="bot-field" />
    </div>
  )
}

export default Layout
