import "./styles.scss"

import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

const Header = ({ siteTitle }) => {
  const [navOpen, setNavOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allPrismicLayout {
        edges {
          node {
            data {
              header_logo {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <header className={`header ${navOpen ? "nav-open" : ""}`}>
      <div className="header__container">
        <img
          className="header__logo"
          src={data.allPrismicLayout.edges[0].node.data.header_logo.fluid.src}
          alt="Chauffeur Services London"
        />
        <div className="header__burger" onClick={() => setNavOpen(!navOpen)}>
          <span className="header__burger--line"></span>
          <span className="header__burger--line"></span>
          <span className="header__burger--line"></span>
        </div>
      </div>
      <nav className="header__nav">
        <ul>
          <li className="header__nav--item">
            <Link to="/">Home</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/about">About</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/services">Services</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/fleet">Fleet</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/rates">Rates</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/booking">Make a Booking</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/quote">Get a Quote</Link>
          </li>
          <li className="header__nav--item">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
