import "./Footer.scss"

import { Link } from "gatsby"
import React from "react"
import usePrismicFooterQuery from "./Footer.graphql"
import Logo1 from "../../images/lphca.png"
import Logo2 from "../../images/nla-white.png"


const Footer = () => {
  const {
    company_address: { html: addressHtml }, // destructured DEEEEP and renamed html to addressHtml
    phone_number_1: { text: phone1 },
    phone_number_2: { text: phone2 },
    registration_info: { html: registrationHtml },
  } = usePrismicFooterQuery()

  return (
    <footer>
      <section>
        <div
          className="address"
          dangerouslySetInnerHTML={{ __html: addressHtml }}
        ></div>

        <div
          className="info"
          dangerouslySetInnerHTML={{ __html: registrationHtml }}
        ></div>
      </section>
      <section className="logos">
        <div className="lphca"><img src={Logo1} alt="LPHCA Member" /><p>Licensed Private Hire Car Association</p></div>

        <img className="nla" src={Logo2} alt="NLA Member" />

      </section>
      <section className="right">
        <div className="phone-1">
          <a href={`tel:${phone1}`}>{phone1}</a>
        </div>
        <div className="phone-2">
          <a href={`tel:${phone2}`}>{phone2}</a>
        </div>

        <ul>
          <li>
            <Link to="/terms">Terms & Conditions</Link>
          </li>
        </ul>
      </section>
    </footer>
  )
}

export default Footer
